<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else>
      <div v-if="grades.length">

        <h4 class="mt-4 mb-3 text-center">Журнал учета успеваемости (летний семестр)</h4>
        <div class="mb-5 text-center">{{grades[0].name}}</div>

        <div v-if="!isPractice">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">№</th>
              <th scope="col">Баркод</th>
              <th scope="col">ФИО</th>
              <th scope="col">ВСК 1</th>
              <th scope="col">ВСК 2</th>
              <th scope="col">Экзамен</th>
              <th scope="col">Общий балл</th>
              <th scope="col">GPA</th>
              <th scope="col">Оценка</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(student, studentIndex) in grades" :key="studentIndex">
              <th scope="row">{{studentIndex+1}}</th>
              <td>{{student?.barcode}}</td>
              <td>{{student?.last_name}} {{student?.first_name}} {{student?.middle_name}}</td>
              <td>{{student.vsk1}}</td>
              <td>{{student.vsk2}}</td>
              <td>{{student.exam}}</td>
              <td>{{student.total}}</td>
              <td>{{student.num_gpa}}</td>
              <td>{{student.letter_equiv}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-else>

          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">№</th>
              <th scope="col">Баркод</th>
              <th scope="col">ФИО</th>
              <th scope="col">GPA</th>
              <th scope="col">Оценка</th>
              <th scope="col">Общий балл</th>
              <th scope="col">Действие</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(student, studentIndex) in grades" :key="studentIndex">
              <th scope="row">{{studentIndex+1}}</th>
              <td>{{student?.barcode}}</td>
              <td>{{student?.last_name}} {{student?.first_name}} {{student?.middle_name}}</td>
              <td>{{student.num_gpa}}</td>
              <td>{{student.letter_equiv}}</td>
              <td>
                <div v-if="+student.close_status===1">
                  {{student.total}}
                </div>
                <div v-else>
                  <input class="form-control" type="number" max="100" min="0" placeholder="Итоговый"
                         v-model="student.total">
                </div>
              </td>
              <td>
                <div v-if="+student.close_status===1">
                  Оценка поставлена
                </div>
                <div v-else>
                  <Button icon="pi pi-save" class="p-button-rounded" :loading="loadingSaveGrade"
                          @click="saveGrade(student.student_ratings_id)"/>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

        </div>

      </div>
      <h5 v-else class="text-center mt-4">Курс не найден</h5>

    </div>
  </div>

</template>

<script>

  import {mapActions, mapState} from "vuex"

  export default {
    name: "TeacherSummerGrades",
    data() {
      return {
        summer_course_id: +this.$route.query.summer_course_id || 0,
        loading: true,
        grades: [],
        loadingSaveGrade: false
      }
    },
    computed: {
      ...mapState('teacherSummer', ['teacherSummer']),
      isPractice() {
        return this.grades.length && (+this.grades[0].is_practice) === 1
      },
    },

    methods: {
      ...mapActions('teacherSummer', ['GET_SUMMER_GRADES_BY_SUMMER_COURSE', 'POST_TOTAL_GRADE']),
      async saveGrade(student_ratings_id) {
        this.loadingSaveGrade = true
        const gradeIndex = this.grades.findIndex(i => i.student_ratings_id === student_ratings_id)
        if (gradeIndex >= 0) {
          const data = await this.POST_TOTAL_GRADE({
            student_ratings_id: this.grades[gradeIndex].student_ratings_id,
            total: this.grades[gradeIndex].total
          })
          if (data && data?.id) {
            await this.getSummerGrades()
            this.$message({title: 'Оценка успешно поставлена'})
          }
        }
        this.loadingSaveGrade = false
      },
      async getSummerGrades() {
        if (this.summer_course_id) {
          await this.GET_SUMMER_GRADES_BY_SUMMER_COURSE(this.summer_course_id)
          this.grades = [...this.teacherSummer.grades]
        }
      }
    },
    async mounted() {
      await this.getSummerGrades()
      this.loading = false
    }

  }
</script>

<style scoped>

</style>